/*

Polo - Multipurpose HTML5 Template
Version: 5.9.9
Website: https://inspirothemes.com/themes/polo
License: https://inspirothemes.com/themes/polo/license.html
Inspiro Themes (https://inspirothemes.com)
Author: INSPIRO - Ardian Berisha
Copyright 2021

*/

//Google Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800|Nunito:300,400,600,700,800');

// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/functions";

// Polo variables
@import "variables";

// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
//@import "../../node_modules/bootstrap/scss/utilities";

// Mixins
@import "mixins";

// Bootstrap optional
//@import "bootstrap";

// Polo
//@import "core";
//@import "layout";

// utilities
@import "utilities";

// widgets
@import "widgets";

// Base
@import "base/include";

// Vendor
@import "vendor";

// Vendor
@import "components";

// Elements
@import "elements/include";

.welcome-section {
  &__content {
    max-width: 65em;
    margin-left: auto;
    margin-right: auto;
  }
}

.login-button {
  min-width: 87px !important;
  min-height: 34px !important;
  border-radius: 36px !important;
  border: 1px solid !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 5px 15px !important;
  margin-left: 12px !important;

  @media (max-width: 991.98px) {
    margin-left: 0 !important;
  }

  &:hover {
    background-color: #1B76F4 !important;
    color: #FFF !important;
    border-color: #1B76F4 !important;
  }
}
