/* ----------------------------------------------------------------------
	Parallax
-------------------------------------------------------------------------*/
#page-title[data-bg-parallax],
[data-bg-parallax] {
    background-color: $gray-900;
}

.parallax-container {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -100;
    opacity: 0;
    transition: opacity .5s linear;
}

.parallax-container.parallax-responsive {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center !important;
}

.img-loaded {
    opacity: 1;
}