/* ----------------------------------------------------------------
	Alert
-----------------------------------------------------------------*/
.alert {
    color: $white;
    padding: 0.85rem 1.5rem;
    z-index: 9999;

    span[data-notify~="title"] {
        display: block;
        font-weight: bold;
    }

    span.fas {
        font-size: $icon-size;
        float: left;
        padding-top: 10px;
        min-width: 40px;
        min-height: 40px;
    }

    .close {
        background: none;
        border: 0;
        color: $white;
        font-weight: 300;
        //padding: 12px 18px;
    }

    >.alert-icon {
        float: left;

        i {
            font-size: 34px;
        }
    }

    >p {
        padding-left: 54px;
    }

    &.alert-primary {
        background-color: $primary;
        border-color: darken($primary, 5%);
    }

    &.alert-secondary {
        background-color: $secondary;
        border-color: darken($secondary, 5%);
    }

    &.alert-success {
        background-color: $success;
        border-color: darken($success, 5%);
    }

    &.alert-info {
        background-color: $info;
        border-color: darken($info, 5%);
    }

    &.alert-danger {
        background-color: $danger;
        border-color: darken($danger, 5%);
    }

    &.alert-warning {
        background-color: $warning;
        border-color: darken($warning, 5%);
    }

    &.alert-light {
        color: $gray-900;
        background-color: $gray-100;
        border-color: $border-color;

        .alert-link,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $gray-900;
        }
    }

    &.alert-dark {
        background-color: $gray-900;
        border-color: darken($gray-900, 5%);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .alert-link {
        color: $white;
    }

    [data-notify="progressbar"] {
        height: 2px !important;
        margin-top: 8px;
    }
}
