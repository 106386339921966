//Dropdowns

.dropdown {
  display: inline-block;
}


.nav-link,
.dropdown-toggle {
  &:after {
    border: 0;
    margin: 0;
    display: none;
  }
}

.dropdown-submenu > .dropdown-item:not(.no-icon):not(.rounded-circle),
.dropdown-toggle:not(.no-icon):not(.rounded-circle) {
  position: relative;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  &:after {
    border: 0;
  }
  &:after {
    content: "";
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    border-top: 0.0625rem solid $gray-600;
    border-right: 0.0625rem solid $gray-600;
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
    right: $dropdown-item-padding-x;
  }
}

//Dropdown Icon version
.dropdown {
  > .btn:not(.no-icon):not(.rounded-circle) {
    padding-right: $dropdown-item-padding-x * 1.1;
  }
}

.dropdown-menu {
  font-size: $dropdown-font-size;
  box-shadow: $dropdown-box-shadow;
  padding: $dropdown-padding-y $dropdown-padding-x;
}

.dropdown-item {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
  font-size: $dropdown-link-font-size;
  font-weight: $dropdown-link-font-weight;

  .badge {
    margin: 0 0.5rem;
  } 
}

.dropdown-color-item {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
  margin-top: 2px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}

.dropdown-header {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;

 font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    letter-spacing: .3px;
  }






 