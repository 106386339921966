/* ----------------------------------------------------------------------
    Clients Logo
-------------------------------------------------------------------------*/
.client-logo {
  position: relative;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    transition: $transition-base;

    >img {
      width: 100%;
      height: auto;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.grid li {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.9;
}

.clients-carousel a {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.9;

  img {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.9;
  }
}

.parallax .clients-carousel a {
  opacity: 1;
}

.grid li:hover {
  opacity: 1;
  cursor: pointer;
}

.clients-carousel a:hover {
  opacity: 1;
  cursor: pointer;

  img {
    opacity: 1;
    cursor: pointer;
  }
}

.client-carousel .polo-carousel-item img,
.clients-logo .polo-carousel-item img {
  padding: 20px;
}

.grid {
  padding: 0;
  list-style: none;
  overflow: hidden;

  li {
    float: left;
    position: relative;
    width: 20%;
    padding: 40px;
  }

  &.grid-2-columns {

    li,
    figure {
      width: 50%;
    }
  }

  &.grid-3-columns {

    li,
    figure {
      width: 33.333333%;
    }
  }

  &.grid-4-columns {

    li,
    figure {
      width: 25%;
    }
  }

  &.grid-5-columns {

    li,
    figure {
      width: 20%;
    }
  }

  &.grid-6-columns {

    li,
    figure {
      width: 16.6666666%;
    }
  }

  li {
    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }

    a,
    img {
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    a img {
      width: 100%;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      height: 100%;
      top: 0;
      left: -1px;
      border-left: 1px solid #eee;
    }

    &:after {
      width: 100%;
      height: 0;
      top: auto;
      left: 0;
      bottom: -1px;
      border-bottom: 1px solid #eee;
    }
  }

  &.clients-grey {
    border: 1px solid #ddd;

    li {
      background-color: #eee;

      &:before {
        border-left: 1px solid #ccc;
      }

      &:after {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  &.clients-border {
    border-width: 10px;
  }
}

/*client options*/
/*carousel logo*/
.carousel-description-clients {
  .flickity-page-dots {
    bottom: -40px !important;
  }
}

/*Clients Responsive li*/
body {

  &.breakpoint-md .carousel-description-clients .flickity-button,
  &.breakpoint-xs .carousel-description-clients .flickity-button {
    display: none;
  }

  &.breakpoint-md .grid li,
  &.breakpoint-sm .grid li {
    width: 33.33%;
  }

  &.breakpoint-xs .grid li {
    width: 50%;
  }
}

.client-logos {
  .polo-carousel-item {
    padding: 20px 30px;
  }

  &--revert {
    transform: scaleX(-1) translateX(-2%);

    @media (max-width: 784px) {
      transform: scaleX(-1) translateX(-3%);
    }

    @media (max-width: 540px) {
      transform: scaleX(-1) translateX(-4%);
    }

    @media (max-width: 380px) {
      transform: scaleX(-1) translateX(-7%);
    }

    .polo-carousel-item {
      transform: scaleX(-1);
    }
  }
}
